import {Button, Checkbox, Form, Input} from 'antd';
import React from 'react';
import useMarket from "../hooks/useMarketV2";
import {ref, set} from "firebase/database";
import {rdb} from "../firebaseConfig";
import {v4 as uuidv4} from 'uuid';


const RoomCreate = ({closeModal}) => {
    const [form] = Form.useForm();
    const {initPricesAndSupplyByRdb} = useMarket(null);

    const generateRoomId = () => {
        return uuidv4().replace(/-/g, '');
    };

    function initLobbyState(roomId: string, roomName, isPublic, itemPlacementToggle, liveLink) {
        const lobbyStateRef = ref(rdb, `rooms/${roomId}/lobbyState`);
        set(lobbyStateRef, {
            roomName: roomName,
            isPublic: isPublic,
            itemPlacementToggle: itemPlacementToggle,
            liveLink: liveLink || null,
            isLive: false,
            mapMetadata: {
                type: 'Empty',
                rounds: 0
            }
        });
    }

    const handleFormCancel = () => {
        closeModal();
        form.resetFields();
    }

    const handleFormSubmit = (values) => {
        const {roomName, isPublic, itemPlacementToggle, liveLink} = values;
        const roomId = generateRoomId();
        initPricesAndSupplyByRdb(roomId);
        initLobbyState(roomId, roomName, isPublic, itemPlacementToggle, liveLink);
        handleFormCancel();
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFormSubmit}
            >
                <Form.Item
                    label="Room Name"
                    name="roomName"
                    rules={[{required: true, message: 'Please enter the room name'}]}
                >
                    <Input placeholder="Enter room name"/>
                </Form.Item>

                <Form.Item
                    name="isPublic"
                    initialValue={true}
                    valuePropName="checked">
                    <Checkbox disabled={true}>Public Room</Checkbox>
                </Form.Item>

                <Form.Item
                    name="itemPlacementToggle"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox>Enable Item Placement Toggle</Checkbox>
                </Form.Item>

                <Form.Item
                    label="Live Stream Link"
                    name="liveLink"
                    rules={[{required: false, message: 'Please enter the live stream link'},
                        {type: 'url', message: 'Please enter a valid URL'}]}
                >
                    <Input placeholder="Enter live stream link"/>
                </Form.Item>

                <Form.Item>
                    <Button
                        className="text-white px-2 py-2 rounded-full text-sm font-medium
                    flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        type="primary" htmlType="submit" block>
                        Create Room
                    </Button>
                    <Button
                        className="px-2 py-2 rounded-full text-sm
                                    font-medium flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        type="default" onClick={handleFormCancel} block style={{marginTop: '10px'}}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default RoomCreate;
