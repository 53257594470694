import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal} from 'antd';
import 'firebase/firestore';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../firebaseConfig";

const InvitationModal = ({playerData: playerData, logout, user}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        setIsModalVisible(true);
        if (playerData && !playerData.isRegistered) {
            setIsModalVisible(true);
        }
    }, [playerData]);

    const handleOk = async () => {
        if (['rps-666', 'rps-888'].includes(inviteCode)) {
            if (playerData) {
                const userRef = doc(db, 'users', user.id);
                await updateDoc(userRef, {
                    isRegistered: true,
                    inviteCode: inviteCode,
                    name: username
                });
            }
            message.success('Successful registration')
            setIsModalVisible(false);
        } else {
            message.error('Failed, please check your invitation code')
            return
        }

    };

    const handleCancel = () => {
        logout()
        setIsModalVisible(false);
    };

    const handleInputChange = (e) => {
        setInviteCode(e.target.value);
    };

    if (!playerData || playerData.isRegistered) {
        return null;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <Modal
            title="Invitation Registration"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Register"
            cancelText="Cancel"
            maskClosable={false}
            wrapClassName="backdrop-blur"
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}
                        className="bg-gradient-to-r from-purple-500 to-purple-700 hover:from-purple-700 hover:to-purple-500 text-white">
                    Register
                </Button>,
            ]}
        >
            <p className="mb-4">Our game is currently invite-only.</p>
            <div>You can follow our community to get an invitation link.</div>
            <div>Join us on <a href="https://discord.gg/HHyH6epY" target="_blank" rel="noopener noreferrer"
                               className="text-purple-600 hover:text-purple-800">Discord</a>.
            </div>
            <Input
                className={'mt-4'}
                placeholder="Enter your invite code"
                value={inviteCode}
                onKeyPress={handleKeyPress}
                onChange={handleInputChange}
            />
            <Input
                className={'mt-4'}
                placeholder="Enter your username"
                value={username}
                onKeyPress={handleKeyPress}
                onChange={(e) => setUsername(e.target.value)}
            />
        </Modal>
    );
};

export default InvitationModal;
