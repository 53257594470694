// @ts-ignore
import React, {useEffect, useRef, useState} from 'react';
import {app, db, rdb} from './firebaseConfig';
import {
    AnimatedItem,
    GameData,
    GameItem,
    GameState,
    Inventory,
    LobbyState,
    PlayerDataV2 as PlayerData,
    PriceHistoryEntry
} from './typeDefinitions';
import {create} from 'zustand';
import {doc, getDoc, onSnapshot, setDoc, updateDoc} from 'firebase/firestore';
import {get, off, onValue, query as rQuery, ref, update} from "firebase/database"
import useMarket from "./hooks/useMarketV2";
import {BondingCurvePrice} from './bondingCurvePrice';
import GameHistoryWidget from './gameComponents/GameHistoryWidgetV2';
import {Utils} from "./utils";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {message, notification, Tour, TourProps} from 'antd';
import LiveComments from "./gameComponents/LiveCommentsV2";
import GameInterface from "./gameComponents/GameInterfaceV2";
import LiveStreamWindow from './gameComponents/LiveStreamWindowV2';
import useSound from 'use-sound';
import PriceChart from "./gameComponents/priceChartV2";
import {useNavigate} from "react-router-dom";
// Connect to the emulator

const functions = getFunctions(app);
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development') {
//     console.log("connecting to emulator");
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

const logPriceChange = async (newPrices: { Rock: number; Paper: number; Scissors: number }, roomId) => {
    const priceHistoryEntry: PriceHistoryEntry = {
        ...newPrices,
        timestamp: Date.now(),
    };

    try {
        const priceHistoryRef = doc(db, `rooms/${roomId}/priceHistory`, Date.now().toString());
        await setDoc(priceHistoryRef, priceHistoryEntry);
        console.log("Price change logged:", priceHistoryEntry);
    } catch (error) {
        console.error("Error logging price change:", error);
    }
};

const useGameStore = create<GameState>((set) => ({
    id: "0",
    playerEntries: {},
    pot: 0,
    round: 1,
    animatedItems: [],
    startTime: Date.now(),
    status: 'active',
    players: {},
    isSimulating: false,
    winningType: null, // Add this line to fix the missing property
}));

function getRoomIdFromPath() {
    const pathParts = window.location.pathname.split('/').filter(Boolean);
    return pathParts.length > 0 ? pathParts[0] : '';
}

export const RockPaperScissorsGame = ({playerData, setPlayerData, user}) => {
    const navigate = useNavigate()
    // game canvas settings
    const canvasWidth = 800;
    const canvasHeight = 600;
    const objectSize = 40;
    const speedMultiplier = 10;
    const gameState = useGameStore();
    const [result, setResult] = useState<string>('');
    const [isSimulating, setIsSimulating] = useState<boolean>(false);
    const [roomId, setRoomId] = useState<string>(getRoomIdFromPath());
    const [animatedItems, setAnimatedItems] = useState<AnimatedItem[]>([]);
    const [currentGameId, setCurrentGameId] = useState<string | null>(null);
    const {itemPrices, setMarket, loadPricesAndSupplyByRdb, getMarketData, updateMarketData} = useMarket(roomId);
    const [userNetWorth, setUserNetWorth] = useState<number | null>(null);
    const [targetGameStartTime, setTargetGameStartTime] = useState<number | null>(null);
    const [playerNames, setPlayerNames] = useState<Record<string, string>>({});
    const [isDeploying, setIsDeploying] = useState(false);
    const [isTrade, setIsTrade] = useState(false);
    const [lobbyState, setLobbyState] = useState<LobbyState>({
        liveLink: null,
        isLive: false,
        roomName: '',
        isPublic: true,
        itemPlacementToggle: true,
        mapMetadata: {
            type: 'Empty',
            rounds: 0
        }
    });
    const [playDeploySound] = useSound('/assets/deploy_item.mp3', {volume: 0.1});
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1600);
    const [isTinyScreen, setIsTinyScreen] = useState(window.innerWidth < 1400);
    // 初始化状态，尝试从 localStorage 读取，如果没有则默认为 false
    const [showTour, setShowTour] = useState(localStorage.getItem('showTour') === null);
    const closeTour = () => {
        setShowTour(false);
        localStorage.setItem('showTour', 'false');
    };

    const canvasRef = useRef(null);
    const buyRef = useRef(null);
    const deployRef = useRef(null);
    const steps: TourProps['steps'] = [
        {
            title: 'Welcome',
            description: 'Welcome to the rps wars game',
            placement: 'center',
            closeIcon: false,
            nextButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FF4500, #1E90FF)',
                    color: 'white',
                    border: 'none'
                },
            },
            target: () => null,
        },
        {
            title: 'How To Buy',
            description: 'You can choose the item you want to buy here.',
            closeIcon: false,
            prevButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FFA500, #FF4500)',
                    color: 'white',
                    border: 'none'
                },
            },
            nextButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FF4500, #1E90FF)',
                    color: 'white',
                    border: 'none'
                },
            },
            target: () => buyRef.current,
        },
        {
            title: 'How To Deploy',
            description: 'Then select the corresponding item to bet on',
            closeIcon: false,
            prevButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FFA500, #FF4500)',
                    color: 'white',
                    border: 'none'
                },
            },
            nextButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FF4500, #1E90FF)',
                    color: 'white',
                    border: 'none'
                },
            },
            target: () => deployRef.current,
        },
        {
            title: 'How To Earn',
            description: 'When the game starts, your item will be here to participate in the competition',
            closeIcon: false,
            prevButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FFA500, #FF4500)',
                    color: 'white',
                    border: 'none'
                },
            },
            nextButtonProps: {
                style: {
                    background: 'linear-gradient(to right, #FF00FF, #00FFFF)',
                    color: 'white',
                    border: 'none'
                },
            },
            target: () => canvasRef.current,
        },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1600);
            setIsTinyScreen(window.innerWidth < 1400);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const loadPlayerNames = async () => {
            const names: Record<string, string> = {};
            for (const playerId of Object.keys(gameState.playerEntries || {})) {
                names[playerId] = await getPlayerName(playerId);
            }
            setPlayerNames(names);
        };

        loadPlayerNames();
    }, [gameState.playerEntries]);


    useEffect(() => {
        // seperate listener for lobby state
        const lobbyStateRef = ref(rdb, `rooms/${roomId}/lobbyState`);
        const lobbyStateListener = onValue(lobbyStateRef, (snapshot) => {
            if (snapshot.exists()) {
                const lobbyState = snapshot.val() as LobbyState;
                console.log("lobbyState", lobbyState);
                setLobbyState(lobbyState);
            } else {
                notification.error({
                    message: "Error",
                    description: "The room doesn't exist."
                });
                navigate('/')
            }
        }, (error) => {
            console.error("Error listening to lobby state:", error);
        });

        if (!initialLoadRef.current) {
            loadAllGamesFromRdb()
            initialLoadRef.current = true;
        }

        // Add lobbyStateListener to the cleanup function
        return () => {
            console.log("Cleaning up lobby state listener");
            lobbyStateListener();
        };
    }, []); // Make sure this dependency array includes all necessary dependencies

    const refreshUserState = async () => {
        if (!user || !user.id) return;
        try {
            const userRef = doc(db, 'users', user.id);
            const userDoc = await getDoc(userRef);
            // 如果存在刷新一下用户
            if (userDoc.exists()) {
                const userData = userDoc.data() as PlayerData;
                // 如果刷新的时候发现用户再该房间没有库存，初始化库存
                if (!userData.inventories || !userData.inventories[roomId]) {
                    console.log('userData.inventories init')
                    userData.inventories = {
                        ...userData.inventories,
                        [roomId]: {Rock: 0, Paper: 0, Scissors: 0}
                    };
                    await setDoc(userRef, userData);
                }
                console.log("Refreshed user state:", userData);
                setPlayerData(userData);
            } else {
                message.error("User does not exist, Please log in again");
            }
        } catch (error) {
            console.error("Error refreshing user state:", error);
        }
    };

    useEffect(() => {
        loadPricesAndSupplyByRdb();

        //Listen for broadcast messages
        const broadcastRef = ref(rdb, `rooms/${roomId}/broadcast`);
        // Start of Selection
        // Start of Selection
        const broadcastListener = onValue(broadcastRef, async (snapshot) => {
            const broadcastData = snapshot.val();
            if (broadcastData && broadcastData.type === 'NEW_GAME') {
                console.log("Received broadcast for new game:", broadcastData.gameId);

                // Load the current game info from RDB once
                const gameRef = ref(rdb, `rooms/${roomId}/games/${broadcastData.gameId}`);
                get(gameRef).then((gameSnapshot) => {
                    const gameData = gameSnapshot.val();
                    if (gameData) {
                        console.log("Loaded current game info:", gameData);
                        // Set the current game ID

                        // clear current canvas and game state
                        setAnimatedItems([]);
                        useGameStore.setState({
                            id: broadcastData.gameId,
                            playerEntries: {},
                            pot: 0,
                            startTime: Date.now(),
                            status: 'active',
                            players: {},
                            isSimulating: false,
                        });

                        // If it is simulating, then start the simulation
                        if (gameData.status === 'isSimulating') {
                            console.log("start the game simulation");
                            setIsSimulating(true);
                        }

                        // if already completed then skip
                        if (gameData.status === 'completed') {
                            console.log("game already completed from broadcast ref");
                            return
                        }

                        setCurrentGameId(broadcastData.gameId);
                        // shud we update the user state again to make sure its up to date?

                        // Set target game start time
                        setTargetGameStartTime(broadcastData.targetGameStartTime);
                        console.log("targetGameStartTime", broadcastData.targetGameStartTime);

                        useGameStore.setState(gameData);
                    } else {
                        console.log("No game data found for gameId:", broadcastData.gameId);
                    }
                }).catch((error) => {
                    console.error("Error fetching game data:", error);
                });
            }
        });


        // Listen for current game changes
        let currentGameListener: any = null;
        if (currentGameId) {
            console.log("load currentGameId", currentGameId);
            const gameRef = ref(rdb, `rooms/${roomId}/games/${currentGameId}`);
            currentGameListener = onValue(gameRef, (snapshot) => {
                const data = snapshot.val() as GameState;
                console.log("current game updated", data);
                if (data) {
                    console.log("data", data);
                    console.log("Current game updated:", currentGameId, data.status);
                    if (!data.playerEntries) {
                        data.playerEntries = {}
                    }

                    if (data.status === 'completed') {
                        // update the game state once completed
                        console.log("game completed, refresh user state");
                        refreshUserState();
                        // update the game state again
                        useGameStore.setState(data);
                        return;
                    }

                    if (data.status === 'isSimulating') {
                        console.log("data.status", data.status);
                        console.log("start the game simulation");
                        const allItemCoordinates: Array<AnimatedItem> = Object.values(data.playerEntries)
                            .reduce<Array<AnimatedItem>>((accumulator, currentEntry) => {
                                return accumulator.concat(currentEntry.itemCoordinates);
                            }, []);

                        // Include system items
                        if (data.systemItems && Array.isArray(data.systemItems)) {
                            allItemCoordinates.push(...data.systemItems);
                        }

                        if (allItemCoordinates.length > 0) {
                            setAnimatedItems(prevState => [
                                ...prevState,
                                ...allItemCoordinates
                            ]);
                        }
                        setIsSimulating(true);
                    }
                    if (data.status === 'active' || data.status === 'countdown') {
                        const allItemCoordinates: Array<AnimatedItem> = Object.values(data.playerEntries)
                            .reduce<Array<AnimatedItem>>((accumulator, currentEntry) => {
                                return accumulator.concat(currentEntry.itemCoordinates);
                            }, []);

                        // Include system items
                        if (data.systemItems && Array.isArray(data.systemItems)) {
                            allItemCoordinates.push(...data.systemItems);
                        }

                        if (allItemCoordinates.length > 0) {
                            setAnimatedItems(prevState => [
                                ...prevState,
                                ...allItemCoordinates
                            ]);
                        }
                    }
                    useGameStore.setState(data);
                } else {
                    console.log("No game data found for current game");
                }
            }, (error) => {
                console.error("Error listening to current game data:", error);
            });
        }

        // Cleanup function
        return () => {
            console.log("Cleaning up game listeners");
            if (currentGameId && currentGameListener) {
                off(ref(rdb, `rooms/${roomId}/games/${currentGameId}`), 'value', currentGameListener);
            }
        };
    }, [currentGameId]);

    const calculateNetWorth = (inventory: PlayerData['inventories'], money: number): number => {
        const inventoryWorth = Object.entries(inventory[roomId]).reduce((total, [item, quantity]) => {
            const itemPrice = itemPrices[item]?.price || 0;
            return total + itemPrice * quantity;
        }, 0);
        return inventoryWorth + money;
    };

    useEffect(() => {
        if (!user || !user.id) return;
        const roomId = getRoomIdFromPath();
        // 加载用户数据
        refreshUserState().then(r => console.log('loadCurrentUser success'));
        // 设置doc的自动更新监听器
        const unsubscribe = onSnapshot(doc(db, 'users', user.id), (docSnapshot) => {
            console.log("User data updated:", docSnapshot.data());
            if (docSnapshot.exists()) {
                // 更新user doc 最新值
                const userData = docSnapshot.data() as PlayerData;
                setPlayerData(userData);
                // 计算netWorth
                if (userData && userData.inventories && userData.inventories[roomId]) {
                    const netWorth = calculateNetWorth(userData.inventories, userData.money);
                    console.log("netWorth", netWorth);
                    setUserNetWorth(netWorth);
                }
            }
        }, (error) => {
            console.error("Error listening to user data:", error);
        });
        return () => {
            console.log("Cleaning up user listener");
            unsubscribe();
        };
    }, [user]);

    // to load all games and set the current game id
    const initialLoadRef = useRef(false);
    const loadAllGamesFromRdb = async () => {
        try {
            const gamesRef = ref(rdb, `rooms/${roomId}/games`);
            const sortedGamesRef = rQuery(gamesRef);

            const snapshot = await get(sortedGamesRef);
            const games: GameData[] = [];
            let currentGameFound = false;
            snapshot.forEach((childSnapshot) => {
                const gameData = childSnapshot.val() as GameData;
                games.push({
                    ...gameData,
                    id: childSnapshot.key
                });

                if (!currentGameFound && gameData.status === 'active') {
                    setCurrentGameId(childSnapshot.key);
                    currentGameFound = true;
                }
            });
            games.sort((a, b) => b.startTime - a.startTime);
        } catch (error) {
            console.error("Error loading games:", error);
        }
    };


    const buyItemByRdb = async (item: keyof Inventory, quantity: number = 1) => {
        if (!user?.id) return;

        try {
            setIsTrade(true);
            const marketData = await getMarketData();
            if (!marketData) {
                return
            }
            const playerRef = doc(db, 'users', user.id);
            const playerSnap = await getDoc(playerRef);
            if (!playerSnap.exists()) {
                message.error("Player does not exist!")
                return
            }

            const playerData = playerSnap.data() as PlayerData;
            if (playerData.money < marketData[item].price * quantity) {
                message.error("Not enough money")
                return
            }
            if (!playerData.inventories || !playerData.inventories[roomId]) {
                playerData.inventories = {
                    ...playerData.inventories,
                    [roomId]: {Rock: 0, Paper: 0, Scissors: 0}
                }
            }

            // use the slope to calculate the price
            const curve = new BondingCurvePrice(quantity);
            const buyResult = curve.buy(marketData[item].sold, quantity);

            // minus the cost
            const newMoney = playerData.money - buyResult['cost'];
            const newInventory = {
                ...playerData.inventories[roomId],
                [item]: (playerData.inventories[roomId][item] || 0) + quantity
            };

            const newMarketSupply = marketData[item].sold + quantity;
            const newPrice = buyResult['newPrice'];

            // 更新市场数据
            const updates = {
                [`${item}/price`]: newPrice,
                [`${item}/sold`]: newMarketSupply,
            };
            const updatedMarketData = await updateMarketData(updates);

            // 更新玩家数据
            await updateDoc(playerRef, {
                money: newMoney,
                inventories: {...playerData.inventories, [roomId]: newInventory},
            });

            // Log the price change
            await logPriceChange({
                Rock: updatedMarketData['Rock'].price,
                Paper: updatedMarketData['Paper'].price,
                Scissors: updatedMarketData['Scissors'].price,
            }, roomId);


            // If transaction succeeds, update local state
            setPlayerData(prevData => {
                if (!prevData) return null;
                return {
                    ...prevData,
                    money: newMoney,
                    inventories: {...playerData.inventories, [roomId]: newInventory}
                };
            });

            setMarket(prevMarket => ({
                ...prevMarket,
                [item]: {
                    price: newPrice,
                    sold: newMarketSupply
                }
            }));

        } catch (error) {
            console.error("Error buying item:", error);
            // Handle error (e.g., show error message to user)
        } finally {
            setIsTrade(false);
        }
    };

    const sellItemByRdb = async (item: keyof Inventory, quantity: number = 1) => {
        if (!user?.id) return;

        try {
            setIsTrade(true);
            const playerRef = doc(db, 'users', user.id);
            const playerSnap = await getDoc(playerRef);
            if (!playerSnap.exists()) {
                message.error("Player does not exist!")
                return
            }

            const playerData = playerSnap.data() as PlayerData;
            if (playerData.inventories[roomId][item] - quantity < 0) {
                message.error("Not enough items to sell")
                return
            }

            const marketData = await getMarketData()
            if (!marketData) {
                message.error("Market data does not exist!")
                return
            }

            // const fee = basePrice * 0.05; // 5% fee
            // const sellPrice = basePrice - fee;
            // use the slope to calculate the price
            const curve = new BondingCurvePrice(quantity)
            const sellResult = curve.sell(marketData[item].sold, quantity);

            console.log("sellResult", sellResult);
            // minus the cost
            const newMoney = playerData.money + sellResult['revenue'];
            const newPrice = sellResult['newPrice'];
            const newSold = marketData[item].sold - quantity;

            const newInventory = {
                ...playerData.inventories[roomId],
                [item]: playerData.inventories[roomId][item] - quantity
            };


            // 更新玩家数据
            await updateDoc(playerRef, {
                money: newMoney,
                inventories: {...playerData.inventories, [roomId]: newInventory},
            });

            // 更新市场数据
            const updates = {
                [`${item}/price`]: newPrice,
                [`${item}/sold`]: newSold,
            };

            const updatedMarketData = await updateMarketData(updates)

            // log the price change
            await logPriceChange({
                Rock: updatedMarketData['Rock'].price,
                Paper: updatedMarketData['Paper'].price,
                Scissors: updatedMarketData['Scissors'].price,
            }, roomId);


            // If transaction succeeds, update local state
            setPlayerData(prevData => {
                if (!prevData) return null;
                return {
                    ...prevData,
                    money: newMoney,
                    inventories: {...playerData.inventories, [roomId]: newInventory}
                };
            });

            setMarket(prevMarket => ({
                ...prevMarket,
                [item]: {
                    price: newPrice,
                    sold: newSold
                }
            }));

        } catch (error) {
            console.error("Error selling item:", error);
            // Handle error (e.g., show error message to user)
            alert("Error selling item");
        } finally {
            setIsTrade(false);
        }
    };

    const placeOnWallByRdb = async (item: keyof Inventory,
                                    x: number = Math.random() * (canvasWidth - objectSize * 2) + objectSize,
                                    y: number = Math.random() * (canvasHeight - objectSize * 2) + objectSize) => {
        // 没开局
        if (playerData == null || user == null || !currentGameId) return;
        // 游戏开局前半秒, 无法下注
        if (!targetGameStartTime || targetGameStartTime - new Date().getTime() <= 500) {
            message.warning("Game is starting or has done, cannot buy item");
            return
        }

        try {
            // 加锁
            setIsDeploying(true);
            // 更新用户数据
            const playerRef = doc(db, 'users', user.id);
            const playerSnap = await getDoc(playerRef);
            if (!playerSnap.exists()) {
                message.error("Player does not exist!")
                return
            }

            const playerData = playerSnap.data() as any;
            // 为空
            if (!playerData.inventories || playerData.inventories[roomId] == null) {
                message.error("Not enough items to place on wall")
                return
            }
            // 不够
            if (playerData.inventories[roomId][item] - 1.0 < 0) {
                message.error("Not enough items to place on wall")
                return
            }
            // 更新游戏数据
            const gameRef = ref(rdb, `rooms/${roomId}/games/${currentGameId}`);
            const gameSnapshot = await get(gameRef);
            const gameData = gameSnapshot.val() as GameData;
            console.log("gameData", gameData.status);
            // if the game is not active do not allow place
            if (gameData.status === 'completed' || gameData.status === 'isSimulating') {
                console.log("Game is not active, cannot place item");
                message.warning(`Game is ${gameData.status}, cannot place item`)
                return;
            }

            const newInventory = {...playerData.inventories[roomId], [item]: playerData.inventories[roomId][item] - 1};

            // 更新玩家数据

            await updateDoc(playerRef, {
                inventories: {...playerData.inventories, [roomId]: newInventory},
            });

            if (!gameData.playerEntries) {
                gameData.playerEntries = {};
            }
            const playerEntry = gameData.playerEntries[user.id] || {
                Rock: 0,
                Paper: 0,
                Scissors: 0,
                itemCoordinates: []
            };
            // 新增的item

            const newItem: AnimatedItem = {
                id: playerEntry.itemCoordinates.length + 1,
                name: Utils.formatName(playerData.name) || '',
                type: item,
                x: x,
                y: y,
                vx: (Math.random() - 0.5) * speedMultiplier,
                vy: (Math.random() - 0.5) * speedMultiplier
            }
            setAnimatedItems([...playerEntry.itemCoordinates, newItem]);
            const newPlayerEntry = {
                ...playerEntry,
                [item]: playerEntry[item] + 1,
                itemCoordinates: [...playerEntry.itemCoordinates, newItem]
            };
            const updates = {
                [`playerEntries/${user.id}`]: newPlayerEntry,
                ['pot']: (gameData.pot || 0) + itemPrices[item].price,
            };
            await update(gameRef, updates)

            // Update local state after successful transaction
            setPlayerData(prevData => {
                if (!prevData) return null;
                return {
                    ...prevData,
                    inventories: {...playerData.inventories, [roomId]: newInventory}
                };
            });
            // play the sound
            if (playDeploySound) {
                playDeploySound();
            }
        } catch (error) {
            console.error("Error placing item on wall:", error);
            alert(error);
        } finally {
            setIsDeploying(false);
        }
    };

    const simulateGameByRdb = async () => {
        // 没登录不能开游戏
        if (!user) {
            message.warning("Please login to start the game");
            return
        }
        const startNewGameFunction = httpsCallable(functions, 'start_new_game');
        const result = await startNewGameFunction({room_id: roomId});
        if (!(result.data as any).success) {
            message.warning((result.data as any).message)
        } else {
            message.success('Game in Progress.....')
        }
        console.log('Start new game result:', result);
    };

    const distributeRewards = async (gameWinningType: GameItem) => {
        if (!user) {
            return;
        }
        try {
            // if game state is is dsitruitng rewards then dont call this
            // fetch the game
            setIsSimulating(false);

            const gameRef = ref(rdb, `rooms/${roomId}/games/${currentGameId}`);
            const gameDoc = await get(gameRef);
            const gameData = gameDoc.val() as GameData;
            // rewards already distributed
            if (gameData.status === 'completed') {
                console.log("game is already distributing rewards, do nothing");
                return;
            }

            // no winner for this game, do not call rewards distribution
            if (gameWinningType == null) {
                console.log("No winner for this game, ending without distributing rewards");
                // do not allow reward distribution
                return;
            }

            const distributeRewardsFunction = httpsCallable(functions, 'distribute_rewards');
            console.log("distributing rewards for game", currentGameId, gameWinningType);
            const result = await distributeRewardsFunction({
                game_id: currentGameId,
                winning_type: gameWinningType,
                room_id: roomId
            });

            console.log('Rewards distributed:', result.data);

        } catch (error) {
            console.error('Error distributing rewards:', error);

        }
    };


    const getPlayerName = async (playerId: string): Promise<string> => {
        const userRef = doc(db, 'users', playerId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            const userData = userSnap.data() as PlayerData;
            return userData.name || playerId;
        }
        return playerId;
    };

    return (
        <div>
            <div className="bg-[#111827] rounded-lg shadow-md p-1">
                <div>
                    {result && (
                        <div
                            className="mt-4 mb-4 p-4 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-lg shadow-lg animate-pulse">
                            <p className="text-2xl font-extrabold text-white text-center tracking-wider uppercase">
                                🔥 {result} 🚀
                            </p>
                            <div className="mt-2 flex justify-center">
                                {['💎', '🌈', '🎉', '💯'].map((emoji, index) => (
                                    <span key={index} className="text-3xl mx-1 animate-bounce">{emoji}</span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex flex-wrap justify-center mx-2 lg:mx-2 mx-0">
                    <div className={`w-full ${isSmallScreen ? 'lg:w-1/5' : 'lg:w-1/6'} p-2 hidden lg:block`}>
                        <LiveComments
                            playerData={playerData}
                            roomId={roomId}
                        />
                    </div>

                    <div className={`w-full ${isSmallScreen ? 'lg:w-3/5' : 'lg:w-3/6'} p-2 lg:px-2 px-0 flex justify-center`}>
                        {/* <div className="max-w-[800px] w-full"> */}
                            <GameInterface
                                canvasRef={canvasRef}
                                deployRef={deployRef}
                                gameState={gameState}
                                animatedItems={animatedItems}
                                // canvasWidth={canvasWidth}
                                // canvasHeight={canvasHeight}
                                // objectSize={objectSize}
                                isSimulating={isSimulating}
                                setAnimatedItems={setAnimatedItems}
                                distributeRewards={distributeRewards}
                                targetGameStartTime={targetGameStartTime}
                                placeOnWallByRdb={placeOnWallByRdb}
                                lobbyState={lobbyState}
                                playerNames={playerNames}
                                simulateGameByRdb={simulateGameByRdb}
                                playerData={playerData}
                                currentGameId={currentGameId}
                                isDeploying={isDeploying}
                                itemPrices={itemPrices}
                                roomId={roomId}
                            />
                        {/* </div> */}
                    </div>

                    <div
                        className={`${isSmallScreen ? `lg:w-${isTinyScreen ? '2' : '1'}/5` : 'lg:w-2/6'} p-2 mr-auto`}>
                        <LiveStreamWindow
                            lobbyState={lobbyState}
                            roomId={roomId}
                            setLobbyState={setLobbyState}
                        />
                        <PriceChart
                            onBuy={buyItemByRdb}
                            onSell={sellItemByRdb}
                            itemPrices={itemPrices}
                            isTrade={isTrade}
                            playerData={playerData}
                            userNetWorth={userNetWorth}
                            buyRef={buyRef}
                            roomId={roomId}
                            lobbyState={lobbyState}
                        />
                    </div>
                </div>

            </div>
            <div className="flex justify-center mt-4">
                <div className="w-full lg:w-2/3 p-2">
                    <GameHistoryWidget playerNames={playerNames}
                                       roomId={roomId}
                                       lobbyState={lobbyState}
                    />
                </div>
            </div>
            <Tour indicatorsRender={(current, total) => (<span>{current + 1} / {total}</span>)}
                  open={showTour} onClose={() => closeTour()} steps={steps}/>
        </div>
    );
};

export default RockPaperScissorsGame;
