export class Utils {
    /**
     * 格式化名字，如果名字长度超过6个字符，使用"xx..xx"的格式。
     * @param name 要格式化的名字字符串。
     * @returns 格式化后的名字。
     */
    static formatName(name: string): string {
        if (!name) {
            return '';
        }
        if (name.length > 6) {
            return name.substring(0, 2) + '..' + name.substring(name.length - 2);
        }
        return name;
    }

    static allItems = {'Rock': '🪨', 'Paper': '📄', 'Scissors': '✂️'};

    static getSymbol = (item: string, symbolMap?: Record<string, string>): string => {
        if (symbolMap && item in symbolMap) {
            return symbolMap[item];
        }
        switch (item) {
            case 'Rock':
                return '🪨';
            case 'Paper':
                return '📄';
            case 'Scissors':
                return '✂️';
            default:
                return '';
        }
    };
}
