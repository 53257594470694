import {useEffect, useState} from 'react';
import {get, onValue, ref, set, update} from "firebase/database";
import {rdb} from '../firebaseConfig.js';

interface MarketItem {
    price: number;
    sold: number;
}

interface Market {
    Rock: MarketItem;
    Paper: MarketItem;
    Scissors: MarketItem;
}

const useMarket = () => {
    const [itemPrices, setMarket] = useState<Market>({
        Rock: {price: 1, sold: 0},
        Paper: {price: 1, sold: 0},
        Scissors: {price: 1, sold: 0}
    });

    const getMarketData = async () => {
        const marketRef = ref(rdb, `market/current`);
        const marketDoc = await get(marketRef)
        if (!marketDoc.exists()) {
            return null;
        }
        return marketDoc.val() as Market;
    }


    const updateMarketData = async (updates) => {
        const marketRef = ref(rdb, `market/current`);
        await update(marketRef, updates);
        const updatedMarketData = await get(marketRef);
        return updatedMarketData.val() as Market;
    }

    const loadPricesAndSupplyByRdb = async () => {
        const marketRef = ref(rdb, 'market/current');

        const marketSnapshot = await get(marketRef);
        if (marketSnapshot.exists()) {
            const marketData = marketSnapshot.val();
            setMarket(marketData);
        } else {
            const initialMarket = {
                Rock: {price: 1, sold: 0},
                Paper: {price: 1, sold: 0},
                Scissors: {price: 1, sold: 0}
            };
            await set(marketRef, initialMarket);
            setMarket(initialMarket);
        }
    };
    useEffect(() => {
        const marketRef = ref(rdb, 'market/current');
        // 设置实时监听
        const unsubscribe = onValue(marketRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setMarket(data);
            } else {
                console.log("No data available");
            }
        }, (error) => {
            console.error(error);
        });
        return () => unsubscribe();
    }, []);

    return {itemPrices, setMarket, loadPricesAndSupplyByRdb, updateMarketData, getMarketData};
};


export default useMarket;
