import {Utils} from "../utils";
import React, {useState} from "react";

const GameResult = ({
                        winningType,
                        userRewards,
                        totalPnl,
                        setShowRulesPopup,
                        lobbyState
                    }) => {

    const [showResult, setShowResult] = useState(true);
    return showResult ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 p-4 z-10 overflow-y-auto">
            <div className="bg-gray-800 rounded-xl p-6 max-w-sm w-full relative">
                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center mb-4">
                        <span className="text-4xl mr-2">🎉</span>
                        <span className="text-2xl sm:text-3xl font-bold text-white">{winningType} Wins!</span>
                        <span className="text-4xl ml-2">🏆</span>
                    </div>
                    <button
                        onClick={() => setShowResult(false)}
                        className="absolute top-2 right-2 text-white hover:text-gray-300 transition-colors duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                    {userRewards && totalPnl !== null && (
                        <div className="mt-4 w-full">
                            <h3 className="text-xl font-bold text-white mb-2">Your Earnings</h3>
                            <div className="flex flex-wrap justify-center">
                                {Object.entries(userRewards).map(([item, pnl]) => {
                                    const pnlNumber = pnl as number
                                    const changeColor = pnlNumber > 0 ? 'text-green-400' : (pnlNumber < 0 ? 'text-red-400' : 'text-gray-300');
                                    return (
                                        <div key={item}
                                             className="flex items-center rounded-full px-3 py-1 m-1 bg-gray-700">
                                            <span className="text-2xl mr-1">{Utils.getSymbol(item, lobbyState.symbols)}</span>
                                            <span className={`text-sm font-semibold ${changeColor}`}>
                                                {pnlNumber > 0 ? '+' : ''}{pnlNumber.toFixed(2)}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="mt-4 text-center">
                                <span className="text-xl font-bold text-yellow-300 animate-pulse">
                                    Total PNL:
                                </span>
                                <span className="text-2xl font-extrabold bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text ml-2">
                                    ${totalPnl.toFixed(2)}
                                </span>
                                <div className="mt-2 text-sm">
                                    {totalPnl > 0 ? (
                                        <span className="text-green-400">🚀 You're on fire! Keep it up! 🔥</span>
                                    ) : totalPnl < 0 ? (
                                        <span className="text-red-400">😔 Don't worry, you'll bounce back! 💪</span>
                                    ) : (
                                        <span className="text-yellow-400">🤔 Breaking even? Room to improve! 📈</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <button
                        className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 text-sm"
                        onClick={() => setShowRulesPopup(true)}
                    >
                        <span className="mr-1">ℹ️</span>
                        <span>Learn Game Rules</span>
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}

export default GameResult;
