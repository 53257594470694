import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import 'chartjs-adapter-date-fns';
import {collection, limit, onSnapshot, orderBy, query} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {PriceHistoryEntry} from '../typeDefinitions';
import {Utils} from "../utils";
import {Line} from "react-chartjs-2";
import {Slider} from "antd";

ChartJS.register(...registerables);


const PriceChart = ({
                        onBuy,
                        onSell,
                        itemPrices,
                        isTrade,
                        playerData,
                        userNetWorth,
                        buyRef,
                        roomId,
                        lobbyState
                    }) => {
    const [priceHistory, setPriceHistory] = useState<PriceHistoryEntry[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>(Object.keys(Utils.allItems));

    useEffect(() => {
        const priceHistoryRef = collection(db, `rooms/${roomId}/priceHistory`);
        const priceHistoryQuery = query(priceHistoryRef, orderBy('timestamp', 'desc'), limit(200));

        const unsubscribe = onSnapshot(
            priceHistoryQuery,
            (snapshot) => {
                const data: PriceHistoryEntry[] = [];
                snapshot.forEach((doc) => {
                    data.unshift(doc.data() as PriceHistoryEntry);
                });
                setPriceHistory(data);
            },
            (error) => {
                console.error('Error fetching price history:', error);
            }
        );

        return () => unsubscribe();
    }, []);
    const [itemsCounts, setItemsCounts] = React.useState({});
    const handleItemsCountChange = (item, value) => {
        setItemsCounts(prevCounts => ({
            ...prevCounts,
            [item]: value,
        }));
    };

    function getGradientColor(percentage: number) {
        const startColor = [135, 208, 104];
        const endColor = [255, 204, 199];

        const midColor = startColor.map((start, i) => {
            const end = endColor[i];
            const delta = end - start;
            return (start + delta * percentage).toFixed(0);
        });

        return `rgb(${midColor.join(',')})`;
    }

    const chartData = {
        labels: priceHistory.map((entry) => new Date(entry.timestamp)),
        datasets: selectedItems.map((item) => ({
            label: item,
            data: priceHistory.map((entry) => entry[item]),
            borderColor: item === 'Rock' ? '#ff007f' : item === 'Paper' ? '#00ffff' : '#7fff00',
            backgroundColor: `rgba(${item === 'Rock' ? '255, 0, 127' : item === 'Paper' ? '0, 255, 255' : '127, 255, 0'}, 0.1)`,
            fill: false,
            tension: 0.4,
            pointRadius: 0,
            borderWidth: 2,
        })),
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: '#FFFFFF',
                    font: {family: 'Orbitron, sans-serif'},
                },
            },
            tooltip: {
                mode: 'index' as const,
                intersect: false,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleColor: '#FFFFFF',
                bodyColor: '#FFFFFF',
                titleFont: {family: 'Orbitron, sans-serif', size: 14},
                bodyFont: {family: 'Orbitron, sans-serif', size: 12},
            },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                type: 'time' as const,
                time: {
                    unit: 'minute',
                    displayFormats: {minute: 'HH:mm'},
                },
                ticks: {color: '#FFFFFF', maxRotation: 0, autoSkip: true, maxTicksLimit: 6},
                grid: {color: 'rgba(255, 255, 255, 0.1)'},
            },
            y: {
                ticks: {color: '#FFFFFF', callback: (value: number) => `$${value.toFixed(2)}`},
                grid: {color: 'rgba(255, 255, 255, 0.1)'},
            },
        },
        elements: {
            point: {radius: 0, hitRadius: 10, hoverRadius: 5},
        },
    };

    const getPercentChange = (item: string) => {
        if (priceHistory.length < 2) return 0;
        const oldPrice = priceHistory[0][item];
        const newPrice = priceHistory[priceHistory.length - 1][item];
        return ((newPrice - oldPrice) / oldPrice) * 100;
    };

    const cardTemplate = (text: string, value: number) => {
        return (
            <div className="flex-item p-2 bg-gray-700 rounded-lg border-2 border-gray-400">
                <div className="text-lg font-semibold mb-2" style={{
                    background: 'linear-gradient(to right, #77D88B, #5085e7)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                }}>{text}
                </div>
                <div className="text-yellow-400 text-3xl font-bold">
                    ${value.toFixed(2)}
                </div>
            </div>
        );
    }

    const toggleItem = (item: string) => {
        setSelectedItems(prev =>
            prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
        );
    };

    return (
        <div className="mt-5 mb-8">
            <h2 className="text-2xl font-bold mb-4 text-white" style={{fontFamily: 'Orbitron, sans-serif'}}>
                📈 Market
            </h2>
            <style>
                {`
                  .flex-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between; 
                    align-items: stretch;
                    }
                .flex-item {
                    flex: 1 1 300px; 
                    margin: 2px;
                    overflow: auto; 
                    }
                }
            `}
            </style>
            <div className="bg-gray-900 rounded-lg shadow-md mb-4 mt-2">
                <div className="flex-container">
                    {playerData?.money && cardTemplate('Your buying power', playerData?.money)}
                    {userNetWorth !== null && cardTemplate('Your networth', userNetWorth)}
                </div>
            </div>
            {/*<div className="flex justify-end space-x-8 mb-2">*/}
            {/*    {Object.keys(Utils.allItems).map(item => (*/}
            {/*        <div className="flex items-center">*/}
            {/*            <Checkbox*/}
            {/*                type="checkbox"*/}
            {/*                checked={selectedItems.includes(item)}*/}
            {/*                onChange={() => toggleItem(item)}*/}
            {/*                className={`px-2 py-2 rounded-lg text-sm font-medium bg-gray-700 hover:bg-gray-600`}*/}
            {/*            ><span className={'ml-auto'}>{Utils.allItems[item]}</span></Checkbox>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}
            <div className="bg-gradient-to-r from-gray-900 via-black to-gray-900 p-4 rounded-lg shadow-lg">
                <Line data={chartData} options={chartOptions as any}/>
                <div className="grid grid-cols-3 gap-2 mt-4" ref={buyRef}>
                    {Object.keys(Utils.allItems).map(item => (
                        <div key={item} className="flex flex-col items-center bg-gray-800 rounded-lg p-1">
                            <span
                                className="text-3xl mb-2">{Utils.getSymbol(item, lobbyState.symbols)}</span>
                            <div className="text-white text-sm font-bold mb-2">
                                ${itemPrices[item].price.toFixed(2)}
                                <span
                                    className={`ml-2 text-sm ${getPercentChange(item) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {getPercentChange(item) >= 0 ? '▲' : '▼'}
                                    {Math.abs(getPercentChange(item)).toFixed(2)}%
                </span>
                            </div>
                            <div className="flex items-center justify-between w-full mb-2">
                                <button
                                    disabled={isTrade}
                                    onClick={() => onBuy(item, itemsCounts[item] || 1)}
                                    className="bg-green-600 hover:bg-green-700 text-white px-2 py-2 rounded-full text-sm
                                    font-medium flex-grow mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                                    style={{transition: 'background-color 0.2s'}}
                                >
                                    Buy
                                </button>
                                <button
                                    disabled={isTrade}
                                    onClick={() => onSell(item, itemsCounts[item] || 1)}
                                    className="bg-red-600 hover:bg-red-700 text-white px-2 py-2 rounded-full text-sm font-medium flex-grow"
                                    style={{transition: 'background-color 0.2s'}}
                                >
                                    Sell
                                </button>
                            </div>
                            <Slider defaultValue={itemsCounts[item] || 1}
                                    onChange={(value) => handleItemsCountChange(item, value)}
                                    min={1}
                                    styles={{
                                        tracks: {
                                            background: `linear-gradient(to right, ${getGradientColor(0)} 0%, ${getGradientColor(100)} 100%)`,
                                            height: '6px',
                                        },
                                    }}
                                    style={{width: '80%'}}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PriceChart;
