export class BondingCurvePrice {
    constructor(initialPrice, growthFactor = 0.01, maxSupply = 1000000) {
      this.initialPrice = initialPrice;
      this.growthFactor = growthFactor;
      this.maxSupply = maxSupply;
    }
  
    getPrice(currentSupply) {
      return this.initialPrice * (1 + this.growthFactor * currentSupply);
    }
  
    getAvailableShares(currentSupply) {
      return this.maxSupply - currentSupply;
    }
  
    buy(currentSupply, amount) {
      if (amount > this.getAvailableShares(currentSupply)) {
        throw new Error("Not enough shares available");
      }
  
      const startPrice = this.getPrice(currentSupply);
      const endSupply = currentSupply + amount;
      const endPrice = this.getPrice(endSupply);
      const avgPrice = (startPrice + endPrice) / 2;
      const cost = avgPrice * amount;
  
      return {
        cost,
        newSupply: endSupply,
        newPrice: endPrice
      };
    }
  
    sell(currentSupply, amount) {
      if (amount > currentSupply) {
        throw new Error("Not enough shares owned to sell");
      }
  
      const startPrice = this.getPrice(currentSupply);
      const endSupply = currentSupply - amount;
      const endPrice = this.getPrice(endSupply);
      const avgPrice = (startPrice + endPrice) / 2;
      const revenue = avgPrice * amount;
  
      return {
        revenue,
        newSupply: endSupply,
        newPrice: endPrice
      };
    }
  }
  