import React from 'react';
import {PrivyProvider} from '@privy-io/react-auth';
import './index.css';
import IndexPage from "./IndexPage";
import {ConfigProvider} from "antd";

const theme = {
    token: {
        colorPrimary: '#a062d4',
    },
};

function App() {
    return (
        <PrivyProvider
            appId="cm0sjfvwo06zn38au3gawxsb1"
            config={{
                loginMethods: ['wallet', 'twitter'],
            }}
        >
            <ConfigProvider theme={theme}>
                <IndexPage/>
            </ConfigProvider>
        </PrivyProvider>
    );
}

export default App;
