import React, {useState, useEffect} from 'react';
import {db} from '../firebaseConfig';
import {collection, getDocs, query, orderBy, limit} from 'firebase/firestore';
import useMarket from "../hooks/useMarket";

interface LeaderboardEntry {
    id: string;
    name: string;
    netWorth: number;
}

const GameLeaderboard: React.FC = () => {
    const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
    const [loading, setLoading] = useState(true);
    const {itemPrices} = useMarket(); // Get current item prices

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef);
                const querySnapshot = await getDocs(q);

                const leaderboardData: LeaderboardEntry[] = querySnapshot.docs.map(doc => {
                    const userData = doc.data();
                    const inventory = userData.inventory || {};
                    const money = userData.money || 0;

                    // Calculate net worth
                    const inventoryWorth = Object.entries(inventory).reduce((total, [item, quantity]) => {
                        const itemPrice = itemPrices[item as keyof typeof itemPrices]?.price || 0;
                        return total + itemPrice * (quantity as number);
                    }, 0);
                    const netWorth = inventoryWorth + money;

                    return {
                        id: doc.id,
                        name: userData.name || 'Anonymous',
                        netWorth: netWorth,
                    };
                });

                // Sort by net worth and take top 10
                leaderboardData.sort((a, b) => b.netWorth - a.netWorth);
                setLeaderboard(leaderboardData.slice(0, 10));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching leaderboard:", error);
                setLoading(false);
            }
        };

        fetchLeaderboard();
    }, [itemPrices]);

    if (loading) {
        return <div className="text-center py-4">Loading leaderboard...</div>;
    }

    return (
        <div className="bg-gradient-to-r from-purple-600 to-pink-500 p-4 sm:p-6 rounded-2xl shadow-neon">
            <h2 className="text-2xl sm:text-4xl font-extrabold mb-4 sm:mb-6 text-white text-center animate-pulse">🔥 Leaderboard 🔥</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-sm sm:text-md text-left text-neon-blue">
                    <thead className="text-base sm:text-lg uppercase bg-black bg-opacity-50 text-neon-pink">
                    <tr>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4">Rank</th>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4">Name</th>
                        <th scope="col" className="px-2 sm:px-6 py-2 sm:py-4">Net Worth 💎</th>
                    </tr>
                    </thead>
                    <tbody>
                    {leaderboard.map((entry, index) => (
                        <tr key={entry.id}
                            className="bg-black bg-opacity-30 border-b border-neon-purple hover:bg-black hover:bg-opacity-50 transition duration-300">
                            <th scope="row" className="px-2 sm:px-6 py-2 sm:py-4 font-black whitespace-nowrap text-neon-green">
                                #{index + 1}
                            </th>
                            <td className="px-2 sm:px-6 py-2 sm:py-4 font-bold">{entry.name}</td>
                            <td className="px-2 sm:px-6 py-2 sm:py-4 font-black text-neon-yellow">
                                <span className="animate-bounce inline-block">💎</span>
                                ${entry.netWorth.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GameLeaderboard;
